import axios from 'utils/axios';
import moment from 'moment';

export async function createTask(name, detail, assignedToProviderId, dueDate, assignedToPatientId, protocolTemplateId) {
    try {
        const response = await axios.post(`/api/org-id/{orgId}/clinic-id/{clinicId}/task/create`, {
            name,
            detail,
            assignedToProviderId,
            dueDate,
            assignedToPatientId,
            protocolTemplateId
        });
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function editTask(taskId, assignedToProviderId, dueDate) {
    try {
        const response = await axios.post(`/api/org-id/{orgId}/clinic-id/{clinicId}/task/${taskId}/edit`, {
            assignedToProviderId,
            dueDate
        });
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function closeTask(taskId, closeNote) {
    try {
        const response = await axios.post(`/api/org-id/{orgId}/clinic-id/{clinicId}/task/${taskId}/close`, {
            closeNote
        });
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function bulkCloseTasks(taskIds, closeNote) {
    try {
        const response = await axios.post(`/api/org-id/{orgId}/clinic-id/{clinicId}/task/bulk-close`, {
            taskIds,
            closeNote
        });
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function bulkReassign(taskIds, assignedToProviderId) {
    try {
        const response = await axios.post(`/api/org-id/{orgId}/clinic-id/{clinicId}/task/bulk-reassign`, {
            taskIds,
            assignedToProviderId
        });
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function bulkChangeDate(taskIds, dueDate) {
    try {
        const response = await axios.post(`/api/org-id/{orgId}/clinic-id/{clinicId}/task/bulk-change-date`, {
            taskIds,
            dueDate
        });
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function bulkCreate(name, detail, assignedToProviderId, dueDate, patientPopulation, protocolTemplateId) {
    try {
        const response = await axios.post(`/api/org-id/{orgId}/clinic-id/{clinicId}/task/bulk-create`, {
            name,
            detail,
            assignedToProviderId,
            dueDate,
            patientPopulation,
            protocolTemplateId
        });
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function getTaskList(
    protocolsTask,
    {
        status,
        assignedTo,
        taskType,
        dueBefore,
        protocolStatus,
        protocolTemplate,
        mtdFilterField,
        mtdFilterOperation,
        mtdFilterValue,
        sortField,
        sortDirection
    },
    patientId,
    pageNumber,
    pageSize
) {
    try {
        const params = {
            status,
            pageSize,
            pageNumber,
            protocolsTask,
            mtdFilterField,
            mtdFilterOperation,
            mtdFilterValue,
            mtdStartTS: moment().startOf('month').valueOf(),
            mtdEndTS: moment().endOf('month').valueOf(),
            sortField,
            sortDirection
        };
        // filter the -1 value for All
        if (assignedTo && assignedTo.id && assignedTo.id > 0) {
            params.assignedToProviderId = assignedTo.id;
        }
        // filter the ALL values out
        if (taskType && taskType !== 'ALL') {
            params.taskType = taskType;
        }
        if (dueBefore && dueBefore instanceof Date && !Number.isNaN(dueBefore.getTime())) {
            params.dueBefore = dueBefore.getTime();
        }
        if (patientId) {
            params.patientId = patientId;
        }

        if (protocolsTask && protocolStatus && protocolStatus !== 'ALL') {
            params.protocolsInstanceStatus = protocolStatus;
        }

        if (protocolsTask && protocolTemplate && protocolTemplate?.id) {
            params.protocolsTemplateId = protocolTemplate.id;
        }

        const response = await axios.get(`/api/org-id/{orgId}/clinic-id/{clinicId}/task/list`, {
            params
        });
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function getClinicAssignedTaskCount(clinicId) {
    try {
        const endOfDayTS = moment().hour(23).minute(59).second(59).millisecond(0);
        const response = await axios.get(`/api/org-id/{orgId}/clinic-id/${clinicId}/task-stats/me`, {
            params: {
                dueBefore: endOfDayTS.valueOf()
            }
        });

        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function getTaskComments(taskId) {
    try {
        const response = await axios.get(`/api/org-id/{orgId}/clinic-id/{clinicId}/task/${taskId}/comments`);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function createTaskComment(taskId, comment) {
    try {
        const response = await axios.post(`/api/org-id/{orgId}/clinic-id/{clinicId}/task/${taskId}/comment`, {
            comment
        });
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function getTaskById(taskId) {
    try {
        const response = await axios.get(`/api/org-id/{orgId}/clinic-id/{clinicId}/task/${taskId}`);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            throw e;
        }
        throw new Error('unknown error');
    }
}
